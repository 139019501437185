<template>
  <div class="index" v-cloak>
    <div class="header acea-row">
      <div class="kefu">
        <div>
          <img class="fl" src="@assets/images/logo.png" />
          <p class="fl">如有任何问题，请联系客服</p>
        </div>
        <button @click="$router.push({path: '/customer/chat', name: 'CustomerService'})">在线客服</button>
      </div>
      <!-- 滚动文字区域 -->
      <!-- <div class="advert-top" v-if="message">
        <div class="marquee-wrap">
          <ul class="marquee-box" id="marquee-box">
            <li
              class="marquee-list"
              v-for="i in 3"
              v-html="message"
              :key="i"
              :id="i == 1 ? 'marquee' : ''"
            ></li>
          </ul>
        </div>
      </div> -->
      <div class="query">
        <router-link :to="'/search'" class="search acea-row row-middle">
          <span class="iconfont jym-icon-test12"></span>请输入商品名称
        </router-link>
        <div>
          <!-- <span>
            <router-link :to="`/category`">
              <i class="jym-leimupinleifenleileibie"></i>
              <br />分类
            </router-link>
          </span> -->
          <span>
            <router-link :to="`/cart`">
              <i class="jym-icon-test10"></i>
              <br />购物车
            </router-link>
          </span>
        </div>
      </div>
    </div>

    <!-- 轮播 -->
    <!-- <div class="slider-banner banner">
      <swiper :options="swiperOption" v-if="banner.length > 0">
        <swiper-slide v-for="(item, index) in banner" :key="index">
          <img :src="item.pic" />
        </swiper-slide>
        <div class="swiper-pagination paginationBanner" slot="pagination"></div>
      </swiper>
    </div> -->

    <div class="nav acea-row">
      <router-link
        :to="{
          path: '/goods_list',
          query: { id: item.id, title: item.cate_name },
        }"
        class="item"
        v-for="(item, index) in menus"
        :key="index"
      >
        <div class="pictrue">
          <img v-lazy="item.pic" alt="img" />
        </div>
        <div>{{ item.cate_name }}</div>
      </router-link>
    </div>

    <!-- <div v-if="newGoodsBananr">
      <div class="adver">
        <img v-lazy="newGoodsBananr" alt="img" />
      </div>
    </div>-->

    <!-- 首发新品 -->
    <!-- <div class="wrapper" v-if="info.firstList.length > 0">
      <div class="title acea-row row-between-wrapper">
        <div class="text">
          <div class="name line1">
            首发新品<span class="new font-color-red">NEW~</span>
          </div>
          <div class="line1">{{ info.firstInfo }}</div>
        </div>
        <router-link :to="{ path: '/hot_new_goods/' + 3 }" class="more">
          全部<i class="jym-icon-test59"></i>
        </router-link>
      </div>
      <div class="newProducts">
        <swiper class="swiper-wrapper" :options="swiperProducts">
          <swiper-slide
            class="swiper-slide"
            v-for="(item, index) in info.firstList"
            :key="index"
          >
            <div @click="goDetail(item)">
              <div class="img-box">
                <img v-lazy="item.image" alt="img" />
                <span
                  class="pictrue_log_medium pictrue_log_class"
                  v-if="item.activity && item.activity.type === '1'"
                  >秒杀</span
                >
                <span
                  class="pictrue_log_medium pictrue_log_class"
                  v-if="item.activity && item.activity.type === '2'"
                  >砍价</span
                >
                <span
                  class="pictrue_log_medium pictrue_log_class"
                  v-if="item.activity && item.activity.type === '3'"
                  >拼团</span
                >
              </div>
              <div class="pro-info line1">{{ item.store_name }}</div>
              <div class="money gold-num">
                ￥{{ item.price }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>-->

    <!-- 优惠券 -->
    <div class="wrapper" v-if="couponList.length > 0">
      <div
        class="title acea-row row-between-wrapper"
        style="padding: 0.4rem 0.3rem 0.2rem"
      >
        <div class="text">
          <div class="name line1">优惠券</div>
          <div class="line1">{{ info.bastInfo }}</div>
        </div>
        <router-link :to="`user/get_coupon`" class="more">
          全部
          <i class="jym-icon-test59"></i>
        </router-link>
      </div>
      <div class="discount-list">
        <div class="container" v-for="(item, index) in couponList" :key="index">
          <router-link :to="`/user/get_coupon`">
            <div class="money">
              <div class="left">
                <span>{{ Number(item.coupon_price) }}</span>
                <em class="font-24">元</em>
              </div>
              <div class="center">
                {{ item.title }}
                <!-- <div
                  v-if="item.use_min_price > item.coupon_price"
                >满{{ Number(item.use_min_price) }}减{{ Number(item.coupon_price) }}</div>
                <div v-else>无门槛</div> -->
              </div>
            </div>
            <div class="bot">
              <div class="text ell">
                {{ item.start_time }}
                {{ item.start_time && item.end_time ? "~" : "" }}
                {{ item.end_time }}
              </div>
              <button>去使用</button>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 精品推荐 -->
    <div
      class="wrapper"
      v-if="info.bastList.length > 0"
      style="padding: 0 0.3rem"
    >
      <div class="title acea-row row-between-wrapper">
        <div class="text">
          <div class="name line1">精品推荐</div>
          <div class="line1">{{ info.bastInfo }}</div>
        </div>
        <router-link :to="{ path: '/hot_new_goods/' + 1 }" class="more">
          全部
          <i class="jym-icon-test59"></i>
        </router-link>
      </div>
      <swiper :options="swiperList">
        <swiper-slide>
          <div class="list-container">
            <div
              class="store-list"
              v-for="(item, index) in info.bastList"
              :key="index"
              style="margin-bottom: 0"
            >
              <router-link :to="{ path: `/detail/${item.id}` }">
                <div class="img-box">
                  <img v-lazy="item.image" />
                </div>
                <div class="text-box">
                  <h4>{{ item.store_name }}</h4>
                  <sub>
                    <span class="fl gold-num">
                      <em>￥</em>
                      {{ item.price }}
                    </span>
                  </sub>
                </div>
              </router-link>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <!-- 秒杀专区 -->
    <div class="store-product-list" v-if="benefit.length > 0">
      <div class="title">秒杀专区</div>
      <div class="wrap">
        <div class="container" v-for="(item, i) in benefit" :key="i">
          <router-link :to="{ path: `/detail/${item.id}` }">
            <!-- <i class="jym-miaosha-copy miaosha"></i> -->
            <div class="img-box">
              <img v-lazy="item.image" />
            </div>
            <div class="text-box">
              <h4>{{ item.store_name }}</h4>
              <sub>
                <span class="fl gold-num">
                  <em>￥</em>
                  {{ item.price }}
                </span>
                <span class="fr"><i class="jym-miaosha icon"></i></span>
              </sub>
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- 纹绣专区 -->
    <div class="store-product-list" v-if="customerStoreProduct.length > 0">
      <div v-for="(item, i) in customerStoreProduct" :key="i">
        <div class="title">{{ item.title }}</div>
        <div class="wrap">
          <div class="container" v-for="(obj, j) in item.list" :key="j">
            <router-link :to="{ path: `/detail/${obj.id}` }">
              <div class="img-box">
                <img v-lazy="obj.image" />
              </div>
              <div class="text-box">
                <h4>{{ obj.store_name }}</h4>
                <sub>
                  <span class="fl gold-num">
                    <em>￥</em>
                    {{ obj.price }}
                  </span>
                  <span class="fr store-discount" v-show="obj.discount > 0">
                    <em>{{ obj.discount }}</em>
                    <i class="jym-zhekou"></i>
                  </span>
                </sub>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="wrapper" v-if="benefit.length > 0">
      <div class="title acea-row row-between-wrapper">
        <div class="text">
          <div class="name line1">促销单品</div>
          <div class="line1">{{ info.salesInfo }}</div>
        </div>
        <router-link :to="'/promotion'" class="more">
          全部<i class="jym-icon-test59"></i>
        </router-link>
      </div>
    </div>
    <Promotion-good :benefit="benefit"></Promotion-good>-->
    <Coupon-window
      :coupon-list="couponList"
      v-if="showCoupon"
      @checked="couponClose"
      @close="couponClose"
    ></Coupon-window>
    <div style="height: 1.2rem"></div>
    <Footer :current=3></Footer>
    <div>
      <iframe
        v-if="mapKey && !isWeixin"
        ref="geoPage"
        width="0"
        height="0"
        frameborder="0"
        scrolling="no"
        :src="
          'https://apis.map.qq.com/tools/geolocation?key=' +
          mapKey +
          '&referer=myapp'
        "
      ></iframe>
    </div>

    <!-- 客服 -->
    <mt-popup v-model="serviceVisible" lockScroll="true">
      <i @click="serviceVisible = false" class="jym-close dialog-close"></i>

      <div class="service-list">
        <div v-if="store_customer.length === 1" class="one">
          <img :src="store_customer[0].img" />
          {{ store_customer[0].name }}
        </div>
        <div
          v-else
          v-for="(item, index) in store_customer"
          :key="index"
          class="more"
        >
          <img :src="item.img" />
          {{ item.name }}
        </div>
      </div>
      <p>长按识别二维码，添加客服</p>
    </mt-popup>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "@assets/css/swiper.min.css";
import CouponWindow from "@components/CouponWindow";
import { getShopData, follow } from "@api/public";
import cookie from "@utils/store/cookie";
import { openShareAll, wxShowLocation } from "@libs/wechat";
import { isWeixin } from "@utils/index";
import { goShopDetail } from "@libs/order";
import Footer from "@components/Footer";
const HAS_COUPON_WINDOW = "has_coupon_window";
const LONGITUDE = "user_longitude";
const LATITUDE = "user_latitude";
const MAPKEY = "mapKey";
let vm = null;
export default {
  name: "Index",
  components: {
    swiper,
    swiperSlide,
    CouponWindow,
    Footer,
  },
  props: {},
  data: function () {
    return {
      store_customer: "",
      newGoodsBananr: "",
      isWeixin: isWeixin(),
      followUrl: "",
      subscribe: false,
      followHid: false,
      showCoupon: false,
      serviceVisible: false,
      logoUrl: "",
      banner: [],
      menus: [],
      roll: [],
      activity: [],
      couponList: [],
      customerStoreProduct: [],
      info: {
        fastList: [],
        bastBanner: [],
        firstList: [],
        bastList: [],
      },
      likeInfo: [],
      benefit: [],
      swiperOption: {
        pagination: {
          el: ".paginationBanner",
          clickable: true,
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 2000,
        },
        loop: true,
        speed: 1000,
        observer: true,
        observeParents: true,
        on: {
          tap: function () {
            const realIndex = this.realIndex;
            vm.goUrl(realIndex);
          },
        },
      },
      swiperList: {
        autoplay: {
          disableOnInteraction: false,
          delay: 2000,
        },
        loop: true,
        preventClicks: false,
        speed: 1000,
        observer: true,
        observeParents: true,
      },
      // swiperProducts: {
      //   freeMode: true,
      //   freeModeMomentum: false,
      //   slidesPerView: "auto",
      //   observer: true,
      //   observeParents: true
      // },
      mapKey: "",
      message: "",
    };
  },
  created() {
    vm = this;
  },
  mounted: function () {
    // 延时滚动
    // setTimeout(() => {
    //   this.runMarquee();
    // }, 1000);

    this.getFollow();
    let that = this;
    that.$dialog.loading.open();
    getShopData()
      .then((res) => {
        that.mapKey = res.data.tengxun_map_key;
        cookie.set(MAPKEY, that.mapKey);
        that.logoUrl = res.data.logoUrl;
        that.newGoodsBananr = res.data.newGoodsBananr;
        that.$set(that, "banner", res.data.banner);
        that.$set(that, "menus", res.data.menus);
        that.$set(that, "roll", res.data.roll);
        that.$set(that, "activity", res.data.activity);
        that.$set(that, "info", res.data.info);
        that.$set(that, "likeInfo", res.data.likeInfo);
        that.$set(that, "couponList", res.data.couponList.splice(0, 2));
        that.$set(that, "benefit", res.data.benefit);
        that.$set(that, "customerStoreProduct", res.data.customerStoreProduct);
        that.$set(that, "store_customer", res.data.store_customer);
        if (!that.subscribe && that.followUrl) {
          setTimeout(function () {
            that.followHid = true;
          }, 200);
        } else {
          that.followHid = false;
        }
        if (res.data.site_name) document.title = res.data.site_name;
        that.setOpenShare();
        // this.showCoupon =
        //   !cookie.has(HAS_COUPON_WINDOW) &&
        //   res.data.couponList.some(coupon => coupon.is_use);
        // if (!cookie.get(LATITUDE) && !cookie.get(LONGITUDE)) this.getWXLocation();
        that.$dialog.loading.close();
      })
      .catch((err) => {
        that.$dialog.error(err.msg);
        that.$dialog.loading.close();
      });
  },
  methods: {
    // 轮播图跳转
    goUrl(index) {
      let url = this.banner[index].wap_url;
      let newStr = url.indexOf("http") === 0;
      if (newStr) {
        window.location.href = url;
      } else {
        this.$router.push({
          path: url,
        });
      }
    },
    // 商品详情跳转
    goDetail(item) {
      goShopDetail(item).then(() => {
        this.$router.push({ path: "/detail/" + item.id });
      });
    },
    // 显示客服
    showQrcode() {
      this.serviceVisible = true;
    },
    getWXLocation() {
      if (isWeixin()) {
        wxShowLocation();
      } else {
        if (!this.mapKey)
          console.log("暂无法使用查看地图，请配置您的腾讯地图key");
        let loc;
        // if (_this.$route.params.mapKey) _this.locationShow = true;
        //监听定位组件的message事件
        window.addEventListener(
          "message",
          function (event) {
            loc = event.data; // 接收位置信息 LONGITUDE
            console.log("location", loc);
            if (loc && loc.module == "geolocation") {
              cookie.set(LATITUDE, loc.lat);
              cookie.set(LONGITUDE, loc.lng);
            } else {
              cookie.remove(LATITUDE);
              cookie.remove(LONGITUDE);
              //定位组件在定位失败后，也会触发message, event.data为null
              console.log("定位失败");
            }
          },
          false
        );
      }
    },
    couponClose() {
      cookie.set(HAS_COUPON_WINDOW, 1);
    },
    getFollow() {
      follow()
        .then((res) => {
          this.followUrl = res.data.path;
        })
        .catch(() => {});
    },
    setOpenShare() {
      if (isWeixin()) {
        var configAppMessage = {
          desc: "聚亿美官方商城",
          title: "聚亿美官方商城",
          link: location.href,
          imgUrl: this.logoUrl,
        };
        openShareAll(configAppMessage);
      }
    },

    // 公告通知
    // runMarquee() {
    //   // 获取文字 计算后宽度
    //   var width = document.getElementById("marquee").getBoundingClientRect()
    //       .width,
    //     marquee = document.getElementById("marquee-box"),
    //     disx = 0; // 位移距离
    //   //设置位移
    //   setInterval(() => {
    //     disx--; // disx-=1; 滚动步长
    //     if (-disx >= width) {
    //       disx = 10; // 如果位移超过文字宽度，则回到起点  marquee-list的margin值
    //     }
    //     // marquee.style.transform = 'translateX(' + disx + 'px)'
    //     marquee.style.left = disx + "px";
    //   }, 25); //滚动速度
    // },
  },
};
</script>
<style scoped>
.index .nav {
  padding-top: 2rem;
}
.index .header {
  padding: 0 0 0.1rem 0;
  height: auto;
  position: fixed;
  z-index: 999;
}
.index .header button {
  color: white;
  background: #daa070;
  border-radius: 0.04rem;
  font-size: 0.22rem;
  height: 0.46rem;
  width: 1.2rem;
  text-align: center;
  margin: 0.17rem 0;
  line-height: 0.46rem;
}
.index .query {
  width: 100%;
  padding: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
}
.kefu {
  height: 0.8rem;
  width: 100%;
  background: black;
  color: white;
  font-size: 0.26rem;
  padding: 0 0.3rem;
  display: flex;
  justify-content: space-between;
  line-height: 0.8rem;
}
.kefu img {
  width: auto;
  height: 0.48rem;
  margin: 0.16rem 0;
}
[v-cloak] {
  display: none;
}
.index .wrapper {
  background: white;
}
.index .follow {
  z-index: 100000;
}
.swiper-container {
  z-index: 0;
}
.mint-popup {
  width: 80%;
  text-align: center;
}
.mint-popup img {
  width: 100%;
}
.mint-popup p {
  line-height: 0.8rem;
  font-size: 0.24rem;
  color: #888;
}
.service-list {
  display: flex;
  justify-content: start;
  max-height: 10rem;
  overflow-y: scroll;
}
.service-list .one {
  width: 100%;
}
.service-list .more {
  width: 50%;
}
.advert-top {
  position: relative;
  display: flex;
  width: 100%;
  height: 0.7rem;
  background: black;
  opacity: .6;
  color: #fff;
  font-size: 0.26rem;
  align-items: center;
  color: white;
  padding: 0 .2rem;
}
.ico-horn {
  display: flex;
  width: 0.4rem;
  height: 0.7rem;
  justify-content: center;
  align-items: center;
}
.ico-horn > img {
  width: 0.32rem;
  height: 0.32rem;
}
/* 以下代码与滚动相关 */
.marquee-wrap {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.marquee-box {
  position: absolute;
  top: 50%;
  display: flex;
  white-space: nowrap;
  transform: translateY(-50%);
}
.marquee-list {
  margin-right: 10px;
} /* 此处“px”方便回到起点 */
.marquee-list span {
  padding: 0 0.04rem;
  color: #ffe17b;
  font-weight: 700;
}
</style>
